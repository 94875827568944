import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

// css
import './index.scss'

// Components
import Header from '../components/header'
import Footer from '../components/footer'

export default ({ data, children }) => (
    <div>
        <Helmet>
            <html lang="ja" />
            <meta charset="utf-8" />
            {/* <title>{data.site.siteMetadata.title}</title> */}
        </Helmet>
        <Header />
        {children}
        <Footer />
    </div>
)

export const query = graphql`
    query HomePageQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`
