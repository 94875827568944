import React from 'react'
import styles from './home.module.scss'

import LogoStiim from '../../static/assets/logo_stiim.svg'
import IconEnvelope from '../../static/assets/icon_envelope.svg'
import IconInstagram from '../../static/assets/icon_instagram.svg'
import BadgeBase from '../../static/assets/badge_base.svg'
import IconFacebook from '../../static/assets/icon_facebook.svg'

console.log(styles);

export default ({ }) => (
    <React.Fragment>
        <section className={styles.marquee}>
            <div className={styles.content}>
                <LogoStiim className={styles.logo} />
                <span className={styles.lead}>世界中から集めたパーツで手作りされた一点物アクセサリーや、ここでしか出逢えない特別なジュエリー達が集まった「今、欲しい」を叶えるShopです。</span>
            </div>
        </section>
        <section className={styles.link}>
            <div className={styles.content}>
                <div className={`${styles.inner} ${styles.instagram}`}>
                    <a href="https://www.instagram.com/stiim_tokyo/" target="_blank" title="stiim Instagram">
                        <IconInstagram className={styles.icon} />
                        {/* <LogoInstagram className={styles.logoInstagram} /> */}
                        <span className={styles.logoInstagram}>Instagram</span>
                        <span className={styles.description}>最新アイテムを随時更新!<br />初出し情報も満載!!</span> 
                    </a>
                </div>
                <div className={`${styles.inner} ${styles.shops}`}>
                    <a href="http://stiim.shopselect.net/" target="_blank" title="stiim Online Shop">
                        <span>Online Shop</span>
                        <LogoStiim className={styles.icon} />
                        <span className={styles.description}>stiimのアイテムをオンラインで購入できます。今すぐGET!</span>
                        <BadgeBase className={styles.logoBase} />
                    </a>
                </div>
                <div className={`${styles.inner} ${styles.facebook}`}>
                    <a href="https://www.facebook.com/Stiim-226806184151582/" target="_blank" title="stiim Facebook">
                        <IconFacebook className={styles.icon} />
                        <span className={styles.logoFacebook}>Facebook</span>
                        <span className={styles.description}>最新情報が満載!<br />stiim公式Facebook!</span> 
                    </a>
                </div>
            </div>
        </section>
        <section className={styles.contact}>
            <div className={styles.content}>
                <a className={styles.button} href="mailto:info@stiim-tokyo.com"><IconEnvelope />Contact us</a>
                <span>お問い合わせの方は、<a href="mailto:info@stiim-tokyo.com">info@stiim-tokyo.com</a>までご連絡ください。</span>
            </div>
        </section>
    </React.Fragment>
)