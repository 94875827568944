import React from 'react'
import styles from './footer.module.scss'

const year = new Date().getFullYear();

export default ({ }) => (
    <footer className={styles.footer}>
        <div className={`${styles.footerBar}`}>
            {`© ${year} stiim.`}
        </div>
    </footer>
)