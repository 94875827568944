import React from 'react'
import styles from './header.module.scss'

import Logo from '../../static/assets/logo_stiim.svg';
import IconInstagram from '../../static/assets/icon_instagram.svg';
import IconFacebook from '../../static/assets/icon_facebook.svg';
import IconBase from '../../static/assets/icon_base.svg';


export default ({  }) => (
    <header className={`${styles.header}`}>
        <Logo className={styles.logo} />
        <div className={styles.icons}>
            <a className={styles.icon} href="https://www.instagram.com/stiim_tokyo/" target="_blank" title="stiim Instagram"><IconInstagram /></a>
            <a className={styles.icon} href="https://www.facebook.com/Stiim-226806184151582/" target="_blank" title="stiim Facebook"><IconFacebook /></a>
            <a className={styles.icon} href="http://stiim.shopselect.net/" target="_blank" title="stiim Online Shop"><IconBase /></a>
        </div>
    </header>
)