import React from 'react'
import Layout from '../layouts/layout'

import Home from '../components/home';

export default () => (
    <Layout>
        <Home />
    </Layout>
)
